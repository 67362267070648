import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import EditorEmbed from "../components/EditorEmbed/EditorEmbed";
import EditorEmbedSupport from "../components/EditorEmbedSupport/EditorEmbedSupport";
import LeftNavLayout from "../layouts/LeftNavLayout/LeftNavLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import {
  CommonContext,
  MicrobitCode,
  SanityCampaign,
  SanityPage
} from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";
import { SanityUserGuidePage } from "../model/get-started";

export const pageQuery = graphql`
  query UserGuidePage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    config: sanityUserGuideConfig(language: { eq: $navLanguage }) {
      userGuideMenu {
        _id
        _type
        title
        slug {
          current
        }
      }
    }
    page: sanityUserGuidePage(_id: { eq: $_id }) {
      _id
      _type
      language
      fields {
        editorLinks
      }
      title
      slug {
        current
      }
      _rawContent(resolveReferences: { maxDepth: 7 })
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      translationSponsors {
        ...TranslationSponsors
      }
      licence {
        ...Licence
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "getStarted" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const UserGuidePageTemplate = ({
  pageContext,
  data: {
    menus,
    page,
    config: { userGuideMenu: menu },
    campaigns
  },
  location
}: PageTemplateProps) => {
  const { title, _rawContent, translationSponsors, licence } = page;
  const customEmbeds = {
    microbitCode: ({ node }: { node: MicrobitCode }) => (
      // No context for now but we could improve this.
      <EditorEmbed unit={undefined} code={node} context={page} />
    )
  };
  return (
    <EditorEmbedSupport document={page}>
      <PageLayout
        siteArea={SiteArea.GET_STARTED}
        metadata={{
          title,
          page,
          alternates: pageContext.alternates
        }}
        menus={menus}
        strings={pageContext.strings}
        location={location}
      >
        <LeftNavLayout
          campaigns={campaigns.nodes}
          h1TextId="user-guide"
          h2={title}
          menu={menu.map((d: SanityPage) => ({
            internalLink: d,
            title: d.title,
            _key: d._id
          }))}
          content={_rawContent}
          translationSponsors={translationSponsors}
          licence={licence}
          customEmbeds={customEmbeds}
        />
      </PageLayout>
    </EditorEmbedSupport>
  );
};

export default UserGuidePageTemplate;

interface PageTemplateProps {
  data: {
    menus: GlobalMenus;
    config: any;
    page: SanityUserGuidePage;
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
